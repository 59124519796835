
.inactive-badge-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .inactive-badge {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(7, 0, 0);
    background-color: rgba(252, 250, 250, 0.5);
    /* background-color: rgb(250, 252, 252); */

    font-size: 2vw;
    font-weight: bold;
    padding: 8px;
    box-sizing: border-box;
    transform: translateY(-50%);
  }
  
  .dim-image {
    filter: grayscale(50%);
    opacity: 0.5;
  }