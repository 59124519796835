.w-100 {
    width: 100%;
}

.m-auto {
    margin: auto;
}

.mt-0 {
    margin-top: 0px !important;
}

.ml-20 {
    margin-left: 20px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.pt-0 {
    padding-top: 0px !important;
}

.d-inline {
    display: inline;
}

.guide-container {
    max-width: 900px;            
    margin: auto;
    padding: 20px !important;
    /* background-color: rgb(244, 249, 255);     */
}

.title {
    font-size: 30px;
    text-transform: uppercase;
    text-align: center;    
    padding: 30px auto;
    margin-top: 20px;
}

.header {
    text-align: left;
    padding: 18px auto;
    font-size: 22px;
    text-decoration: underline;
    /* border-bottom: 1px solid #ddd; */
    margin-top: 2rem;    
    margin-bottom: 1rem;    
    /* background-color: #f7f7f7; */
    color: #36454F;
    text-transform: uppercase;
}

.sub-header {
    text-align: left;
    padding: 10px auto;
    font-size: 20px;
    /* border-bottom: 1px solid #ddd; */
    margin-top: 1rem;    
    margin-bottom: .5rem;
    color: 	#353935;
}

.content {
    text-align: justify;
    letter-spacing: .2px;
    font-size: 16px;
    margin-bottom: 20px;
}

.unordered-list {
    list-style: disc;
    list-style-position: inside;
    padding-top: 10px;
    margin-left: 20px;
    margin-top: 16px;
}

.unordered-list li {
    margin-bottom: 12px;
}

.user-guide-image {
    width: 100%;
    border: 1px solid #eee;
    border-radius: 6px;    
    /* adnimation */
    animation: appear linear;
    animation-timeline: view();
    animation-range: entry 0% cover 30%;
}

.user-guide-image:hover {
    cursor: zoom-in;        
}

.user-guide-image-fullscreen {
    width: 100%;
    /* border: 1px solid #eee; */
    border-radius: 6px;    
    /* border-top-right-radius: 45px; */
}

.user-guide-image-fullscreen:hover {
    cursor: zoom-out;        
}

.userGuide-modal .ant-modal-body {
    padding: 0px !important;
}

@keyframes appear {
    from {
        opacity: 0;
        transform: scale(0.7);
    }
    to {
        opacity: 1;
        transform: scale(1);        
    }

}