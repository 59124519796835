.videoframe {
    width: 95%;
    border-radius: 10px;        
}

@media (max-width: 768px) {
    .videoframe
    {
        height: 340px;
    }
}

@media (min-width: 768px) {
    .videoframe
    {
        height: 270px;
    }
}

@media (min-width: 992px) {
    .videoframe
    {
        height: 350px;
    }
}

@media (min-width: 1100px) {
    .videoframe
    {
        height: 400px;
    }
}


@media (min-width: 1350px) {
    .videoframe
    {
        height: 460px;        
    }
}

@media (min-width: 1500px) {
    .videoframe
    {
        height: 560px;
    }
}