.wrapper {
    padding: 5px;
    position: relative;
    overflow-x: hidden;
    max-width: 1000px;
    background: #fff;
    border-radius: 13px;
  }
  .wrapper .icon {
    position: absolute;
    top: 0;
    height: 100%;
    width: 120px;
    display: flex;
    align-items: center;
  }
  .icon:first-child {
    left: 0;    
    background: linear-gradient(90deg, #fff 70%, transparent);
  }
  .icon:last-child {
    right: 0;
    justify-content: flex-end;
    background: linear-gradient(-90deg, #fff 70%, transparent);
  }
  .icon i {
    width: 55px;
    height: 55px;
    cursor: pointer;
    font-size: 1.2rem;
    text-align: center;
    line-height: 55px;
    border-radius: 50%;
  }
  .icon i:hover {
    background: #efedfb;
  }
  .icon:first-child i {
    margin-left: 15px;
  } 
  .icon:last-child i {
    margin-right: 15px;
  } 
  .wrapper .tabs-box {
    margin: 0px !important;
    display: flex;
    gap: 10px;
    list-style: none;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }
  .tabs-box .tab {
    align-items: center;
    margin: 0px !important;
    cursor: pointer;
    font-size: 14px;
    white-space: nowrap;
    background: #f5f4fd;
    padding: 10px 15px;
    border-radius: 30px;
    border: 1px solid #d8d5f2;
  }
  .tabs-box .tab:hover{
    background: #efedfb;
  }
  .tabs-box .tab.active{
    color: #fff !important;
    background: #2D5D85;
    border-color: transparent;
  }